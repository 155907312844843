import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  SectionTryEasely,
  SectionMakeImpact,
  SectionAssociations,
  SectionGlobalChallenges,
  SectionHowWeCreateValues,
  SectionTopCategories,
  SectionRecentyAddedServices,
  SectionRecentyAddedProducts,
  SectionWhatCommunitySaysAboutUs,
  SectionPartners,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/landing-1200-530.jpg';
import twitterImage from '../../assets/landing-600-314.jpg';
import css from './LandingPage.module.css';
import { getListingsById } from '../../ducks/marketplaceData.duck';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    recentlyAddedServices,
    recentlyAddedProducts,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <section className={css.heroContainer + ' ' + css.firstBox}>
            <SectionHero className={css.hero} history={history} location={location} />
          </section>
          <section className={css.easelySectionWrapper}>
            <SectionTryEasely/>
          </section>
          <div className={css.wrapperClass}>
            <section className={css.sectionWrapper}>
              <SectionMakeImpact />
              {/*<SectionAssociations />*/}
            </section>
          </div>
          <section className={css.easelySectionWrapper}>
            <SectionGlobalChallenges />
          </section>
          <section className={css.easelySectionWrapper}>
            <SectionHowWeCreateValues history={history} />
          </section>
          <section className={css.easelySectionWrapper}>
            <SectionRecentyAddedProducts
              listings={recentlyAddedProducts}
              intl={intl}
            />
          </section>
          <section className={css.easelySectionWrapper}>
            <SectionRecentyAddedServices
              listings={recentlyAddedServices}
              intl={intl}
            />
          </section>
          <section className={css.easelySectionWrapper} >
            <SectionTopCategories history={history} />
          </section>
          <section className={css.easelySectionWrapper}>
            <SectionWhatCommunitySaysAboutUs />
          </section>
          {/*<div className={css.wrapperClass}>*/}
          {/*  <section className={css.sectionWrapper}>*/}
          {/*    <SectionPartners />*/}
          {/*  </section>*/}
          {/*</div>*/}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object, func } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    recentlyAddedServicesIds,
    recentlyAddedProductsIds,
    pagination,
    servicesInProgress,
    servicesError,
    productsInProgress,
    productsError,
  } = state.LandingPage;
  const recentlyAddedServices = getListingsById(state, recentlyAddedServicesIds);
  const recentlyAddedProducts = getListingsById(state, recentlyAddedProductsIds);

  return {
    recentlyAddedServices,
    recentlyAddedProducts,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    servicesInProgress,
    servicesError,
    productsInProgress,
    productsError,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
